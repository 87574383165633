import * as React from "react"
import "./navigate.scss"
import "../../typohraphy.scss"
import { FaArrowRight } from "react-icons/fa"
import { Trans } from 'gatsby-plugin-react-i18next';
import { Link } from "gatsby"

function NavigateLine(props) {
    return (
        <Link to={props.url}>

            <div className="bg-gray d-flex justify-content-between justify-align-center text-center p-5 align-items-center">
                <div>
                    003 <span className="text-secondary"> / 004</span> 
                </div>
                <div>
                    <button className="cta btn btn-lg btn-outline-secondary "><b>See All Work</b></button>
                </div>
                <div>
                <i class="fa-solid fa-arrow-left-long"></i> &nbsp;
                <i class="fa-solid fa-arrow-right-long"></i>
                </div>
            </div>

            <div className="container-fluid navigate" style={props.color}>
                <div className="questrial-50"><Trans>{props.text}</Trans></div><FaArrowRight className="ms-3" size={25} />
            </div>
        </Link>
    )
}

export default NavigateLine;