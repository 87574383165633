import * as React from "react"
import "./cta-type-2.scss"
import "../../typohraphy.scss"
import { BsArrowRight } from "react-icons/bs"

import { Trans } from 'gatsby-plugin-react-i18next';
import { Link } from "gatsby"

function CtaType2(props) {
    return (
        <Link to={props.url}>
            <div className="container-fluid navigate" style={props.color}>
                <div className="questrial-40"><Trans>{props.text}</Trans></div><BsArrowRight className="ms-3" size={25} />
            </div>
        </Link>
    )
}

export default CtaType2;