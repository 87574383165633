import React, { useState, useEffect, useRef } from "react";
import { StaticImage } from "gatsby-plugin-image";
import "./products.scss"
import NavigateLine from "../index/navigate-line/navigate-line";
import CtaType2 from "../cta/cta-type-2/cta-type-2";
import { useI18next } from 'gatsby-plugin-react-i18next';
import axios from "axios";
import { Link } from "gatsby"
import { Trans } from 'gatsby-plugin-react-i18next';
import TitleLogo from "../title-logo/title-logo"

const ProductsPage = () => {
    const { language, languages, changeLanguage } = useI18next();
    const [products, setProducts] = useState([]);
    const slideRef = useRef([]);
    const getProducts = () => {
        if (localStorage.getItem('products') != null) {
            setProducts(JSON.parse(localStorage.getItem('products')));
        }
        axios.get(`https://cmsv2.dact.studio/api/products/${language}`).then(
            (response) => {
                setProducts(response.data);
                localStorage.setItem('products', JSON.stringify(response.data));
            }
        );
    };
    useEffect(() => {
        getProducts();

    }, []);
    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add("visible");
                }
            });
        });

        slideRef.current.forEach(element => {
            if (element && element.current) observer.observe(element.current);
        });

        return () => {
            slideRef.current.forEach(element => {
                if (element && element.current) observer.unobserve(element.current);
            });
        };

    }, [products]);

    return (
        <section className="products-page">
            <TitleLogo siteTitle={"products"} siteComment={<span><a href="/" ><Trans>home</Trans></a></span>} />
            <section>
                <div className="container-fluid text-center mb-1 " id="products">
                    <div className="row bg-white" id="slide-button">
                        {products.map((product, index) => {
                            if (!slideRef.current[index]) {
                                slideRef.current[index] = React.createRef();
                            }
                            return (
                                <div className="col-lg-3 col-md-6 mx-0 px-0 slide-top" ref={slideRef.current[index]}>
                                    <Link to={'/product-detail/' + product.slug}>
                                        <div className={(index % 2 == 0) ? 'card card-1' : 'card card-2'}>
                                            <div className="card-title text-align-right pb-0">
                                                <h5 >{product.name}</h5>
                                            </div>
                                            <img className="products-image img-fluid mx-auto" src={product.cover + '?1'} alt={product.name} />
                                            <div className="card-img-overlay">
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
            {/* <NavigateLine color={{ backgroundColor: "#F8B500", color: "white" }} text="Let's Work Together" url="/contact" /> */}
            <CtaType2 color={{ backgroundColor: "#F8B500" }} text="Let's Work Together" url="/contact" />
        </section>
    )
}
export default ProductsPage;

