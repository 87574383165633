import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ProductsPage from "../components/products/products";
import {graphql} from "gatsby";

const Products = () => (
    <Layout>
        <Seo title="Products" />
        <ProductsPage/>
    </Layout>
)

export default Products

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;